<template>
  <div
    class="popover-content popover-record fade"
    :class="{ show: recordOptions.show }"
  >
    <span class="popover-title excludeDropdown">Grabar este programa:</span>

    <form role="form" novalidate>
      <div class="form-group excludeDropdown">
        <label for="rangeInput">Desde antes del inicio</label>
        <input
          v-model="recordOptions.preGap"
          id="rangeInput"
          class="tplay-range excludeDropdown"
          type="range"
          min="0"
          max="15"
          step="5"
          oninput="amount.value=rangeInput.value"
        />
        <input
          v-model="recordOptions.preGap"
          id="amount"
          class="form-control excludeDropdown"
          readOnly
          type="text"
          min="0"
          max="15"
          oninput="rangeInput.value=amount.value"
        />
        <span>min</span>
      </div>

      <div class="form-group excludeDropdown">
        <label for="rangeInput2">Hasta después de que finalice</label>
        <input
          v-model="recordOptions.postGap"
          id="rangeInput2"
          class="tplay-range excludeDropdown"
          type="range"
          min="0"
          max="15"
          step="5"
          oninput="amount2.value=rangeInput2.value"
        />
        <input
          v-model="recordOptions.postGap"
          id="amount2"
          class="form-control excludeDropdown"
          readOnly
          type="text"
          min="0"
          max="15"
          oninput="rangeInput2.value=amount2.value"
        />
        <span>min</span>
      </div>

      <div
        v-if="asset.serie && asset.serie.idSerie"
        class="row-switch excludeDropdown"
      >
        <span>¿Todos los episodios?</span>
        <div class="tplay-toggle excludeDropdown">
          <input
            v-model="recordOptions.switchSerie"
            type="checkbox"
            id="ModalHeaderTv-switchSerie"
            class="excludeDropdown"
          />
          <label for="ModalHeaderTv-switchSerie">Toggle</label>
        </div>
      </div>

      <div class="row excludeDropdown">
        <button
          v-on:click.prevent="onStartRecording"
          type="button"
          class="btn btn-primary btn-sm"
        >
          OK
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "ModalRecord",

  props: {
    asset: {
      type: Object,
      required: true,
    },
    recordOptions: {
      type: Object,
      required: false,
    },
  },

  computed: {
    recordStatus() {
      if (this.asset.recordData != undefined) {
        return this.asset.recordData;
      } else {
        return this.asset.status;
      }
    },
  },

  methods: {
    onStartRecording() {
      tplay.startRecording(this.asset, this.recordOptions, this.$bus);
      this.$emit("closeModal", { forceCLose: true });
    },
  },
};
</script>
